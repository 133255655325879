import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { CurrencyProvider } from 'dibs-buyer-layout/exports/CurrencyProvider';

import { trackSearchWithinResults } from '../../utils/tracking/searchBrowse/filterTracking';
import { SbSharedRefineMenuFilters } from '../../finding/SbSharedRefineMenu/SbSharedRefineMenuFilters/SbSharedRefineMenuFilters';
import { SbSharedRefineMenuDisplayPrefsSelect } from '../../finding/SbSharedRefineMenu/SbSharedRefineMenuDisplayPrefsSelect/SbSharedRefineMenuDisplayPrefsSelect';
import { SbSharedRefineMenuSearchWithin } from '../../finding/SbSharedRefineMenu/SbSharedRefineMenuSearchWithin/SbSharedRefineMenuSearchWithin';
import { useSbRespRefineMenuFiltersMap } from '../../finding/SbRespRefineMenu/SbRespRefineMenuFilters/sbRespRefineMenuFiltersMap';
import { SbRespStickyMenu } from '../../finding/SbRespStickyMenu/SbRespStickyMenu';
import {
    FILTER_REFINE_SEARCH,
    PRICE,
} from '../../finding/SbSharedRefineMenu/sbSharedRefineMenuConstants';
import { CATEGORY_LEVEL_1 } from '../../utils/categoryHelpers';
import { ATTRIBUTE_ITEMTYPE, ATTRIBUTE_JEWELRYTYPE } from '../../constants/attributesConstants';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';

import { type BuyRespRefineMenu_viewer$data } from './__generated__/BuyRespRefineMenu_viewer.graphql';
import { type BuyRespRefineMenu_filters$data } from './__generated__/BuyRespRefineMenu_filters.graphql';
import { type BuyRespRefineMenu_itemSearch$data } from './__generated__/BuyRespRefineMenu_itemSearch.graphql';
import { type BuyRespRefineMenu_user$data } from './__generated__/BuyRespRefineMenu_user.graphql';

type Props = {
    viewer: BuyRespRefineMenu_viewer$data;
    itemSearch: BuyRespRefineMenu_itemSearch$data;
    user: BuyRespRefineMenu_user$data;
    filters: BuyRespRefineMenu_filters$data;
    isClient: boolean;
    uriRef: string;
};

const BuyRespRefineMenuComponent: FC<Props> = ({
    viewer,
    user,
    filters,
    isClient,
    itemSearch,
    uriRef,
}) => {
    const { appliedFilters } = itemSearch;
    const appliedSearchFilter = appliedFilters?.find(
        filter => filter?.name === FILTER_REFINE_SEARCH
    );
    const showSearchFilter = appliedSearchFilter ? appliedSearchFilter.canBeDismissed : true;

    const { isReorderedFilterMenuVariant } = useServerVarsContext();

    const isCategoryL1Applied = appliedFilters?.some(filter => filter?.name === CATEGORY_LEVEL_1);

    const initiallyExpandedFilters =
        isReorderedFilterMenuVariant && isCategoryL1Applied
            ? [CATEGORY_LEVEL_1, PRICE]
            : [CATEGORY_LEVEL_1, PRICE, ATTRIBUTE_ITEMTYPE, ATTRIBUTE_JEWELRYTYPE];
    const sbRespRefineMenuFiltersMap = useSbRespRefineMenuFiltersMap();

    return (
        <CurrencyProvider
            render={({ currency }: { currency: string }) => (
                <SbRespStickyMenu>
                    {showSearchFilter && (
                        <SbSharedRefineMenuSearchWithin
                            horizontalSpacing="small"
                            onSubmit={trackSearchWithinResults}
                            itemSearch={itemSearch}
                        />
                    )}
                    <SbSharedRefineMenuFilters
                        user={user}
                        uriRef={uriRef}
                        viewer={viewer}
                        filters={filters}
                        currency={currency}
                        isClient={isClient}
                        itemSearch={itemSearch}
                        filterMap={sbRespRefineMenuFiltersMap}
                        initiallyExpanded={filterName =>
                            initiallyExpandedFilters.includes(filterName)
                        }
                    />
                    <SbSharedRefineMenuDisplayPrefsSelect
                        user={user}
                        filters={filters}
                        itemSearch={itemSearch}
                        isClient={isClient}
                    />
                </SbRespStickyMenu>
            )}
        />
    );
};

export const BuyRespRefineMenu = createFragmentContainer(BuyRespRefineMenuComponent, {
    viewer: graphql`
        fragment BuyRespRefineMenu_viewer on Viewer {
            ...SbSharedRefineMenuFilters_viewer
        }
    `,
    itemSearch: graphql`
        fragment BuyRespRefineMenu_itemSearch on ItemSearchQueryConnection {
            appliedFilters {
                name
                canBeDismissed
            }
            ...SbSharedRefineMenuFilters_itemSearch
            ...SbSharedRefineMenuDisplayPrefsSelect_itemSearch
            ...SbSharedRefineMenuSearchWithin_itemSearch
        }
    `,
    filters: graphql`
        fragment BuyRespRefineMenu_filters on SearchBrowseFilter @relay(plural: true) {
            ...SbSharedRefineMenuFilters_filters
            ...SbSharedRefineMenuDisplayPrefsSelect_filters
        }
    `,
    user: graphql`
        fragment BuyRespRefineMenu_user on User {
            ...SbSharedRefineMenuFilters_user
            ...SbSharedRefineMenuDisplayPrefsSelect_user
        }
    `,
});

import { type FC } from 'react';
import { graphql, useFragment } from 'react-relay';

import { SharedItemTile } from '../../shared/SharedItemTile/SharedItemTile';
import { FavoritesProvider } from '../../components/global/FavoriteIcons/FavoritesProvider';
import { QuickViewProvider } from 'dibs-search-product-tile/exports/QuickViewProvider';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { SbRespPlaceholderTile } from '../../finding/SbRespSearchResultContainer/SbRespPlaceholderTile';
import { useSharedUrgencySignals } from '../../finding/hooks/sharedUrgencySignals/useSharedUrgencySignals';
import { useItemShippingPrices } from '../../finding/hooks/useItemShippingPrices';
import { useSbSharedItemTracking } from '../../finding/hooks/sharedItemTracking/useSbSharedItemTracking';
import { useSbSelector } from '../../reducers/useSbSelector';

import {
    VERTICAL_OFFSET_SB_RESP_IMG,
    VERTICAL_OFFSET_MOBILE_IMG,
} from '../../constants/imageConstants';
import { locations } from '../../utils/tracking/shared/favoritesTracking';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';

import { type FavoritesProviderChildrenProps } from '../../components/global/FavoriteIcons/FavoritesProviderChildrenProps';
import { type BuySharedResultContainer_viewer$key } from './__generated__/BuySharedResultContainer_viewer.graphql';
import { type BuySharedResultContainer_buyPage$key } from './__generated__/BuySharedResultContainer_buyPage.graphql';
import { type BuySharedResultContainer_user$key } from './__generated__/BuySharedResultContainer_user.graphql';

import classnames from 'classnames';
import styles from './BuySharedResultContainer.scss';

const LAZY_LOAD_ITEM_ROWS = 3;

const viewerFragment = graphql`
    fragment BuySharedResultContainer_viewer on Viewer {
        ...SharedItemTile_viewer
        ...useSbSharedItemTracking_viewer
    }
`;
const buyPageFragment = graphql`
    fragment BuySharedResultContainer_buyPage on ItemSearchQueryConnection {
        ...SharedItemTile_itemSearch
        edges {
            node {
                item {
                    serviceId
                    ...FavoritesProvider_item
                    ...useSharedUrgencySignals_item
                    ...useItemShippingPrices_item
                    ...useSbSharedItemTracking_item
                    ...SharedItemTile_item
                        @arguments(
                            isTrade: $isTrade
                            priceBookName: $priceBookName
                            showSeller: $showSeller
                        )
                }
            }
        }
        ...useSbSharedItemTracking_itemSearch
    }
`;
const userFragment = graphql`
    fragment BuySharedResultContainer_user on User {
        ...SharedItemTile_user
    }
`;

type Props = {
    viewer: BuySharedResultContainer_viewer$key;
    buyPage: BuySharedResultContainer_buyPage$key;
    user: BuySharedResultContainer_user$key;
    showBuyPageRedesign: boolean;
};

export const BuySharedResultContainer: FC<Props> = ({
    viewer: viewerRef,
    buyPage: buyPageRef,
    user: userRef,
    showBuyPageRedesign = false,
}) => {
    const viewer = useFragment(viewerFragment, viewerRef);
    const buyPage = useFragment(buyPageFragment, buyPageRef);
    const user = useFragment(userFragment, userRef);
    const items = (buyPage?.edges || []).map(edge => edge?.node?.item).filter(filterFalsy);

    const { fetchItemUrgencySignals, getUrgencySignal } = useSharedUrgencySignals(items);
    const { fetchItemShippingPrices, getItemShippingPrices } = useItemShippingPrices(items);
    const { fireItemImpressionTracking, fireItemClickTracking } = useSbSharedItemTracking({
        viewer,
        itemSearch: buyPage,
        items,
        getUrgencySignal,
        getItemShippingPrices,
    });
    const refetchInFlight = useSbSelector(state => state.filters.refetchInFlight);
    const { isClient, userId } = useSbSelector(state => state.relayVariables.variables);
    const { isMobile } = useServerVarsContext();

    const itemsPerRow = showBuyPageRedesign ? 4 : 3;

    return (
        <div className={styles.container}>
            <FavoritesProvider
                disable={!isClient}
                items={items}
                // location should be kept the same GROWTH-4297
                location={locations.SEARCH_BROWSE}
                userId={userId}
                fetchFolder={!isMobile}
            >
                {({ props: favorites }: { props: FavoritesProviderChildrenProps }) => (
                    <QuickViewProvider>
                        <>
                            {items.map((item, index) => {
                                if (refetchInFlight && !isMobile) {
                                    return <SbRespPlaceholderTile key={item?.serviceId} />;
                                }
                                return (
                                    <SharedItemTile
                                        showCreatorName
                                        showSellerName={false}
                                        key={item?.serviceId}
                                        item={item}
                                        user={user}
                                        favorites={favorites}
                                        index={index}
                                        lazyLoadStartIndex={
                                            isMobile ? undefined : LAZY_LOAD_ITEM_ROWS * itemsPerRow
                                        }
                                        imageLoadVerticalOffset={
                                            isMobile
                                                ? VERTICAL_OFFSET_MOBILE_IMG
                                                : VERTICAL_OFFSET_SB_RESP_IMG
                                        }
                                        beforeRender={() => {
                                            fetchItemUrgencySignals(index);
                                            fetchItemShippingPrices(index);
                                        }}
                                        onContentVisible={() =>
                                            fireItemImpressionTracking({
                                                itemId: item?.serviceId,
                                                index,
                                            })
                                        }
                                        onClick={() =>
                                            fireItemClickTracking({
                                                itemId: item?.serviceId,
                                                index,
                                            })
                                        }
                                        customClassName={classnames(styles.tileWidth, {
                                            [styles.desktop4ItemsRow]: showBuyPageRedesign,
                                            [styles.desktop3ItemsRow]: !showBuyPageRedesign,
                                        })}
                                        viewer={viewer}
                                        itemSearch={buyPage}
                                        fetchFolderOnMouseOver
                                        urgencySignal={getUrgencySignal(item.serviceId)?.message}
                                        shippingPrices={getItemShippingPrices(item.serviceId)}
                                    />
                                );
                            })}
                        </>
                    </QuickViewProvider>
                )}
            </FavoritesProvider>
        </div>
    );
};
